import axios from 'axios';

const defaultState = {
  gameInfo: null,
  gameInfoMap: {
    levels: {
      // phone: {
      //   name: 'Phone',
      //   levelNext: 'idle',
      //   images: {
      //     sky: 'sky-first',
      //     city: 'city-first',
      //   },
      //   // messages: [],
      // },
      idle: {
        name: 'Начало',
        levelNext: 'c_1',
        images: {
          sky: 'sky-first',
          city: 'city-first',
        },
        messagesIntro: [
          {
            author: 'npc',
            confirmText: 'Поехали',
            message: 'Авторизуйся через свой нейройнтерфейс, %username%, и мы начнем это испытание. Верим в тебя, храбрец!'
          }
        ],
      },
      // q_1: {
      //   name: 'Квиз 1',
      //   levelNext: 'c_1',
      //   images: {
      //     sky: 'sky-first',
      //     city: 'city-first',
      //   },
      //   win: {
      //     title: 'Слишком просто для тебя',
      //     message: 'Как говорил кто-то великий: "Учение свет, а неучи даже лампочку вкрутить не могут, вот и сидят в темноте.'
      //   },
      //   lose: {
      //     title: 'Поздравляю! ты стал умнее',
      //     message: 'Ну, знаете ли, такое в школах не преподают, так что ничего страшного. У нас ещё 2 квиза впереди'
      //   }
      // },
      c_1: {
        name: 'ФорсПуш',
        levelNext: 'c_2',
        images: {
          sky: 'sky-first',
          city: 'city-first',
        },
        messagesIntro: [
          {
            author: 'npc',
            title: 'ПОБЕДИ ФОРСПУША',
            message: 'Что за отвратное существо... Именно так выглядят последователи учения, призывающего отречься от Git',
          }
        ],
        messages: [
          
        ],
        messagesOnFirstHit: [
          {
            author: 'c_1',
            message: 'И это ты называешь ударом?',
          },
          {
            author: 'npc',
            message: 'Так держать!',
          },
        ],
        messagesOnFirstDamage: [
          {
            author: 'c_1',
            message: 'Твои навыки ничтожны!',
          },
          {
            author: 'npc',
            message: 'Один промах ничего не значит!',
          },
        ],
        messagesOnWin: [
          {
            author: 'c_1',
            message: 'Кха, ты победил меня. Но мои старшие братья не так просты. Радуйся пока можешь, насекомое',
          },
          {
            author: 'npc',
            message: 'Туда тебе и дорога, пережиток прошлого!',
          },
        ],
        messagesOnLose: [
          {
            author: 'c_1',
            message: 'Трепещите перед моей мощью!',
          },
          {
            author: 'npc',
            message: 'Нет, как же так…',
          },
        ],
      },
      // q_2: {
      //   name: 'Квиз 2',
      //   levelNext: 'c_2',
      //   images: {
      //     sky: 'sky-second',
      //     city: 'city-second',
      //   },
      //   win: {
      //     title: 'Круто получилось!',
      //     message: 'Позитивная тенденция — давать правильные ответы. Рад быть твоим предком'
      //   },
      //   lose: {
      //     title: 'Попробуй улучшить показатели!',
      //     message: 'Кхм, ну, главное — боссов побеждать, а остальное приложится. Мы же не на корову играем, верно?'
      //   }
      // },
      c_2: {
        name: 'Регресус',
        levelNext: 'c_3',
        images: {
          sky: 'sky-second',
          city: 'city-second',
        },
        messagesIntro: [
          {
            author: 'npc',
            title: 'ПОБЕДИ РЕГРЕСУСА',
            message: 'Хм, ты это слышал? Только не это... этот лязг сервоприводов мне не забыть никогда. Лишь одно существо способно его издавать, и я очень надеюсь, что это не он',
          }
        ],
        messages: [
          
        ],
        messagesOnFirstHit: [
          {
            author: 'c_2',
            message: 'Аргх, я чувствую в тебе зачатки великой силы, но ты слаб.',
          },
          {
            author: 'npc',
            message: 'Не сдавайся! Ты должен превзойти их, а не примкнуть!',
          },
        ],
        messagesOnFirstDamage: [
          {
            author: 'c_2',
            message: 'Гу-ра-ра-ра, цифровые удобства делают вас слабее!',
          },
          {
            author: 'npc',
            message: 'Держись, потомок!',
          },
        ],
        messagesOnWin: [
          {
            author: 'c_2',
            message: 'Я не мог проиграть… кха, я вижу… свет…?',
          },
          {
            author: 'npc',
            message: 'Осталось совсем чуть-чуть, держись!',
          },
        ],
        messagesOnLose: [
          {
            author: 'c_2',
            message: 'Цифровые хлюпики, ваше время прошло.',
          },
          {
            author: 'npc',
            message: 'Ты проиграл битву, но на смену придут другие!',
          },
        ],
      },
      // q_3: {
      //   name: 'Квиз 3',
      //   levelNext: 'c_3',
      //   images: {
      //     sky: 'sky-third',
      //     city: 'city-third',
      //   },
      //   win: {
      //     title: 'Заканчиваем на отличной ноте',
      //     message: 'Отличный результат, продолжай в том же духе — и ты обязательно станешь старейшиной нашей общины.'
      //   },
      //   lose: {
      //     title: 'Эх, бывает и так',
      //     message: 'Здесь должна быть поговорка про знания, но на нее нет времени. Святыню уносят — быстрее за ней!'
      //   }
      // },
      c_3: {
        name: 'Пирамидус',
        levelNext: 'final',
        images: {
          sky: 'sky-third',
          city: 'city-third',
        },
        messagesIntro: [
          {
            author: 'npc',
            title: 'ПОБЕДИ ПИРАМИДУСА',
            message: 'Будь аккуратен — нам встретится опасный враг. Встроенные в него манипуляторы способны резать сталь, как масло',
          }
        ],
        messages: [
          {
            author: 'c_3',
            message: 'Ваша победа ничего не решит! Я буду жить в каждом объявлении браузера!',
          },
          {
            author: 'npc',
            message: 'Этого не может быть... Пирамидус… Как ты выжил?',
          },
        ],
        messagesOnFirstHit: [
          {
            author: 'c_3',
            message: 'Неплохо, неплохо, но этого всё ещё недостаточно.',
          },
          {
            author: 'npc',
            message: 'Рано радоваться, до победы ещё далеко.',
          },
        ],
        messagesOnFirstDamage: [
          {
            author: 'c_3',
            message: '%username%, ты мне не чита.',
          },
          {
            author: 'npc',
            message: 'Ты дал ему почувствовать вкус крови... добей его!',
          },
        ],
        messagesOnWin: [
          {
            author: 'c_3',
            message: 'Я буду ждать вас... в чистилище.',
          },
          {
            author: 'npc',
            message: 'Слава богу святыня не пострадала!',
          },
        ],
        messagesOnLose: [
          {
            author: 'c_3',
            message: 'Вы не стоите моего времени.',
          },
          {
            author: 'npc',
            message: 'Мы… проиграли...',
          },
        ],
        messageOnWinAfter: [
          {
            author: 'npc',
            message: 'Славная победа. Будущее Интернета уже не столь туманно, но нас ожидают ещё более трудные испытания. Не страшись, потомок, мы всегда будем рядом. И да прибудет с тобой доступ к цифровым удобствам.',
          },
        ],
      },
      final: {
        name: 'Финал',
        levelNext: null,
        images: {
          sky: 'sky-third',
          city: 'city-third',
        },
      },
    },
  },
};

const mutations = {
  SET_GAME_INFO: (state, payload) => {
    state.gameInfo = payload;
  },
  SET_HERO_HEALTH: (state, [level, val]) => {
    state.gameInfoMap.levels[level].heroHealth = val;
  },
  SET_BOSS_HEALTH: (state, [level, val]) => {
    state.gameInfoMap.levels[level].bossHealth = val;
  },
  SET_TRIES: (state, [level, val]) => {
    state.gameInfoMap.levels[level].tries = val;
  },
  GAME_INFO_RESET: (state) => {
    state.gameInfo = JSON.parse(JSON.stringify(defaultState.gameInfo))
  },
  GAME_INFO_MAP_RESET: (state) => {
    state.gameInfoMap = JSON.parse(JSON.stringify(defaultState.gameInfoMap))
  },
};

const actions = {
  async fetchGame({ commit }) {
    console.log('[game] fetchGame start')
    const { data: game } = await axios.get('/game')
    console.log('[game] fetchGame game', game)
    if (game && game.id && game.current_level) {
      console.log('[game] fetchGame GAME STARTED')
      commit('SET_GAME_INFO', game)
    }
    else {
      console.log('[game] fetchGame GAME NEW')
      commit('SET_GAME_INFO', {current_level: 'idle'})
    }
  },
  async startGame () {
    console.log('[game] startGame start')
    await axios.post('/game/new-game')
    console.log('[game] startGame done')
  },
  async restartGame ({ commit, dispatch }) {
    console.log('[game] restartGame start')
    await dispatch('finishGame')
    commit('GAME_INFO_RESET')
    commit('GAME_INFO_MAP_RESET')
    await dispatch('fetchGame')
    console.log('[game] restartGame done')
  },
  async finishGame () {
    console.log('[game] finishGame done')
    // await axios.post('/game/finish')
    await axios.post('/game/new-game')
    console.log('[game] finishGame done')
  },
};

const getters = {};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
