<style lang="scss">
.version {
  position: fixed;
  z-index: 10000;
  bottom: 0px;
  left: 0px;
  background: yellow;
  color: black;
  opacity: 0.3;
  font-size: 10px;
  padding: 0px 4px;
  --clip-size: 6px;
  clip-path: polygon(
    0 0,
    0 -29px,
    calc(100% - var(--clip-size)) 0,
    100% var(--clip-size),
    100% 100%,
    0 100%
  );
}
</style>

<template>
<div>
  <div
    class="version"
  >
    <small @click="versionClick()"> v{{ version }} </small>
  </div>
  <router-view />
</div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    version () {
      return process.env.VUE_APP_VERSION
    }
  },
  methods: {
    versionClick () {
      this.$log('versionClick', process.env)
    },
    visualViewportOnResize (e) {
      // this.$log('visualViewportOnResize', e)
      if (e) {
        this.$store.commit('ui/SET_STATE_PROP', ['offsetTopViewport', e.target.offsetTop])
        this.$store.commit('ui/SET_STATE_PROP', ['offsetLeftViewport', e.target.offsetLeft])
        this.$store.commit('ui/SET_STATE_PROP', ['heightViewport', e.target.height])
        this.$store.commit('ui/SET_STATE_PROP', ['widthViewport', e.target.width])
      }
      this.$store.commit('ui/SET_STATE_PROP', ['height', window.innerHeight])
      this.$store.commit('ui/SET_STATE_PROP', ['width', window.innerWidth])
      const orientation = window.innerHeight >= window.innerWidth ? 'vertical' : 'horizontal'
      this.$store.commit('ui/SET_STATE_PROP', ['orientation', orientation])
    },
    windowOnResize () {
      // this.$log('windowOnResize')
      this.$store.commit('ui/SET_STATE_PROP', ['height', window.innerHeight])
      this.$store.commit('ui/SET_STATE_PROP', ['width', window.innerWidth])
    },
    handleFocusin (e) {
      // this.$log('handleFocusin', e)
      if (e.target.type === 'text' || e.target.type === 'textarea') {
        this.$store.commit('ui/SET_STATE_PROP', ['isFocused', true])
      }
    },
    handleFocusout (e) {
      // this.$log('handleFocusout', e)
      if (e.target.type === 'text' || e.target.type === 'textarea') {
        this.$store.commit('ui/SET_STATE_PROP', ['isFocused', false])
      }
    },
  },
  mounted () {
    this.$log('mounted')
    window.addEventListener('focusin', this.handleFocusin)
    window.addEventListener('focusout', this.handleFocusout)
    // window.visualViewport.addEventListener('resize', this.visualViewportOnResize)
    // this.visualViewportOnResize()
    window.addEventListener('resize', this.windowOnResize)
    this.windowOnResize()
  },
  beforeDestroy () {
    this.$log('beforeDestroy')
    window.removeEventListener('focusin', this.handleFocusin)
    window.removeEventListener('focusout', this.handleFocusout)
    // window.visualViewport.removeEventListener('resize', this.visualViewportOnResize)
    window.removeEventListener('resize', this.windowOnResize)
  }
}
</script>