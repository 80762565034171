import Vue from 'vue';
import axios from 'axios';
import router from '../../router';
import * as Sentry from "@sentry/vue"

const defaultState = {
  authToken: null,
  authResult: '',
  user: {},
};

const mutations = {
  SET_TOKEN: (state, payload) => {
    state.authToken = payload;
    axios.defaults.headers.common.Authorization = `Bearer ${payload}`;
  },
  SET_USER: (state, payload) => {
    Vue.set(state, 'user', payload);
  },
  AUTH_FAILURE: (state) => {
    state.authResult = 'failure';
  },
  AUTH_SUCCESS: (state) => {
    state.authResult = 'success';
  },
  SET_SETTING: (state, { key, value }) => {
    state.settings[key] = value;
  },
};

const actions = {
  setToken({ commit }, payload) {
    commit('SET_TOKEN', payload);
  },
  setUser({ commit }, payload) {
    commit('SET_USER', payload);
  },
  async fetchUser ({ commit, dispatch }) {
    console.log('[user] fetchUser start')
    try {
      const { data } = await axios.get('/me')
      commit('AUTH_SUCCESS')
      commit('SET_USER', data)
      Sentry.setUser({...data, version: process.env.VUE_APP_VERSION || 'old'})
      console.log('[user] fetchUser done', data)
    }
    catch (e) {
      console.log('[user] fetchUser error', e)
      await dispatch('logout')
    }
  },
  async updateUser({ commit }, data) {
    const resp = await axios.post('/me', data)
    commit('SET_USER', resp.data);
    return resp;
  },
  async logout({ commit }) {
    try {
      console.log('[user] logout start')
      await axios.post('/user/logout')
    }
    catch (e) {
      console.log('[user] logout error', e)
    }
    finally {
      commit('SET_USER', {})
      commit('SET_TOKEN', null)
      localStorage.clear()
      console.log('[user] logout done')
      router.replace('/')
    }
  }
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.authToken,
  isAuthenticated: (state) => state.authToken && state.user && state.user.id > 0,
};

export default {
  namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
