import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGtm from "vue-gtm";
import * as Sentry from "@sentry/vue"
import VModal from 'vue-js-modal';
import { TweenMax, TweenLite, TimelineMax } from 'gsap';
import VueMask from 'v-mask'
import store from './store';
import router from './router';
import './css/app.scss'
import './assets/tailwind.css'
import "animate.css"

// Libs
Vue.use(VueMask)
Vue.use(VModal);
Vue.use(VueAxios, axios);

// Sentry
const sentryToken = process.env.VUE_APP_SENTRY
if (sentryToken) {
  Sentry.init({
    Vue: Vue,
    dsn: sentryToken,
    logErrors: true,
    attachProps: true,
  })
  Sentry.setContext("version", {
    version: process.env.VUE_APP_VERSION,
  })
  Vue.prototype.$sentry = Sentry
}

// GTM
const gtmToken = process.env.VUE_APP_GTM
if (gtmToken) {
  Vue.use(VueGtm, {
    id: gtmToken,
    // queryParams: {
    //   // Add url query string when load gtm.js with GTM ID (optional)
    //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
    //   gtm_preview: "env-4",
    //   gtm_cookies_win: "x",
    // },
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  })
}

// Helpers
Vue.prototype.$wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
Vue.prototype.$log = function (...args) {
  console.log(`[${this.$options.name}] `, ...args);
};
Vue.prototype.$tween = TweenMax;
Vue.prototype.$tweenLite = TweenLite;
Vue.prototype.$timeline = TimelineMax;

// Sound
const playersAudio = {}
Vue.prototype.$sound = (id, autoplay = true, volume = 0.5) => {
  if (!store.state.ui.soundOn) return
  if (!playersAudio[id]) {
    playersAudio[id] = new Audio(id)
    playersAudio[id].volume = volume
  }
  if (autoplay) playersAudio[id].play()
  return playersAudio[id]
}

// Axios
axios.defaults.baseURL = process.env.VUE_APP_SERVER_URL + '/api'
axios.defaults.withCredentials = true;

// Auth
const uriParams = new URLSearchParams(window.location.search);
const authToken = uriParams.get('token');

if (authToken) {
  console.log('Token found!', authToken);
  store.commit('user/SET_TOKEN', authToken);
  // window.location = '/game'
}

// Axios interceptors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const allowed = ['/', '/password-reset', '/signup', '/signin', '/privacy', '/test'];
    if (error && error.response) {
      if (error.response.status === 401 && !allowed.includes(window.location.pathname)) {
        // Vue.prototype.$toaster.error('401. Требуется авторизация');
        console.log('401. Требуется авторизация')
        // store.dispatch('user/logout')
      }
      if (error.response.status === 400) {
        // Vue.prototype.$toaster.error(error.response.data.message);
        console.log('400. Сервер не смог обработать запрос')
        // store.dispatch('user/logout')
      }
      if (error.response.status === 500) {
        // Vue.prototype.$toaster.error('500. Что-то пошло не так...');
        console.log('500. Что-то пошло не так...')
        // store.dispatch('user/logout')
      }
      if (error.response.status === 403) {
        // Vue.prototype.$toaster.error('403. Доступ запрещен!');
        console.log('403. Доступ запрещен!')
        // store.dispatch('user/logout')
      }
      if (error.response.status === 404) {
        // Vue.prototype.$toaster.error('404. Не найдено');
        console.log('404. Не найдено')
        // store.dispatch('user/logout')
      }
    }

    throw error;
  },
);

// Enter LandingPage should be as fast as possible!
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
